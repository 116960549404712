import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setHeader } from '../../store/settings/settingsSlice';
import style from "./exchange.module.css";
import { ArrowDown2 } from "iconsax-react";
import info_icon from '../../assets/images/info_icon.svg'
import nigeria_icon from '../../assets/images/nigeria.svg'
import ghana_icon from '../../assets/images/ghanaflag.svg'
import usd_icon from '../../assets/images/usd.svg'
import successIcon from '../../assets/images/success-icon.svg'
import spin_circle from '../../assets/images/Loader anim.png'
import Modal from '../modal/Modal';


const ExchangePage = () => {

    const dispatch = useDispatch()
    const walletData = useSelector((state) => state.wallet.data);
    const [openFromWallet, setOpenFromWallet] = useState(false);
    const [openToWallet, setOpenToWallet] = useState(false);
    const [sourceWallet, setSourceWallet] = useState("");
    const [destinationWallet, setDestinationWallet] = useState("");
    const [isSuccessModal, setSuccessModal] = useState(false);
      const [isOpen, setIsOpen] = useState(false);

    
    const handleFromWallet = (e) => {
        setOpenFromWallet(false);
        setSourceWallet(e.target.textContent);
    };
    const handleToWallet = (e) => {
        setOpenToWallet(false);
        setDestinationWallet(e.target.textContent);
    }

    const toggleFromWallet = () => {
        setOpenFromWallet(!openFromWallet)
    };

    const toggleToWallet = () => {
        setOpenToWallet(!openToWallet)
    };

      useEffect(() => {
            dispatch(setHeader("Exchange"));
     }, [dispatch]);

    return ( 
        <>


       {/* =========================== THE LOADING MODAL TO CREATE EXCHANGE  */}
       <Modal
        canClose={false}
        modalOpen={isOpen}
        setModalOpen={setIsOpen}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={spin_circle} alt="spin circle" className={style.animate_spin} />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Initiating Exchange</h4>
             
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                  Please wait...
                </p>
               
            </div>
        </div>

        </Modal>

          {/* ======== THE MODAL TO STATE THAT EXCHANGE IS SUCCESSFUL */}
          <Modal
        canClose={true}
        modalOpen={isSuccessModal}
        setModalOpen={setSuccessModal}
      >
        <div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                <img src={successIcon} alt="spin circle" />
            </div>

            <div>
                <h4 style={{color: '#40196D', fontWeight: '600', marginTop: '25px'}}>Exchange Successful</h4>
            
                <p style={{color: '#B6B6B6', maxWidth:'300px', margin: 'auto', marginTop: '15px'}}>
                Your wallet has been credited successfully.
                </p>
            </div>

            <button onClick={() => setSuccessModal(false)} style={{marginTop: '20px'}} className={style.save}>
               Continue
            </button>

        </div>

        </Modal>
        
            <div style={{marginTop: '30px'}}>  
                <h5 style={{color: '#1A1A1A', fontWeight: 700, fontSize:14}}>Exchange Details</h5>
                <h6 style={{color: '#1A1A1A', fontSize:13, marginTop: '5px', fontWeight:400}}>Exchange money from one currency to another</h6>
            </div>

            <div className={style.exchangeDiv}>
            <form action="">
           
              <div
              >
                <label htmlFor="from_wallet">From</label>
                <div className={style.select}>
                  <div id="from_wallet"
                    onClick={toggleFromWallet}
                    name="from_wallet" className={style.selectInput}>
                     <div className={style.walletDiv}>
                       {
                        sourceWallet 
                        &&
                        <img
                        style={{ width: "20px", height: '20px', borderRadius: '50%'}}
                         src={sourceWallet === "NGN" ? nigeria_icon:  sourceWallet === "GHS" ? ghana_icon : sourceWallet === "USD" ? usd_icon: ""} alt="country icon" 
                        />
                        }
                        <p style={{fontWeight: `${sourceWallet ? 600: 400}`}} className={style.walletTitle}>{sourceWallet ? sourceWallet : 'Select wallet'}</p>
                     </div>
                  
                    <ArrowDown2
                      style={{
                        transform:
                          openFromWallet === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openFromWallet === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {walletData.fiatWallets ? (
                      walletData.fiatWallets.map((item, index) => (
                        <p
                          id="FromWalletDrop"
                          key={item.id}
                          style={{
                              borderBottom: walletData.fiatWallets.length - 1 === index ? "none" : "1px #90909039 solid;",
                          }} 
                          onClick={(e) => {
                            handleFromWallet(e);
                          }}
                        >
                          {item.Currency.code}
                        </p>
                      ))
                    ) : (
                      <p>Sorry, no wallets here</p>
                    )}
                  </div>
                </div>
              </div>

              <div 
                style={{marginTop: "20px"}}
                 >
                <label htmlFor="amount">Amount<span style={{color: '#FF0000'}}>*</span> </label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="amount"
                    placeholder="Enter amount to exchange"                  
                  />
                </div>
            </div>


              <div
              style={{marginTop: "20px"}}
              >
                <label htmlFor="to_wallet">To</label>
                <div className={style.select}>
                  <div id="to_wallet"
                    onClick={toggleToWallet}
                    name="to_wallet" className={style.selectInput}>
                   <div className={style.walletDiv}>
                       {
                        destinationWallet 
                        &&
                        <img
                        style={{ width: "20px", height: '20px', borderRadius: '50%'}}
                         src={destinationWallet === "NGN" ? nigeria_icon:  destinationWallet === "GHS" ? ghana_icon : destinationWallet === "USD" ? usd_icon: ""} alt="country icon" 
                        />
                        }
                        <p style={{fontWeight: `${destinationWallet ? 600: 400}`}} className={style.walletTitle}>{destinationWallet ? destinationWallet : 'Select wallet'}</p>
                     </div>
                    <ArrowDown2
                      style={{
                        transform:
                          openToWallet === true
                            ? "translateY( -50%) rotate(-540deg)"
                            : "translateY( -50%) rotate(0deg)",
                        pointerEvents: "none",
                      }}
                      className={style.dropDown}
                      size="20"
                      color="#909090"
                      variant="Bold"
                    />
                  </div>
                  <div
                    style={{
                      height: openToWallet === true ? "fit-content" : "0px",
                    }}
                    className={style.option}
                  >
                    {walletData.fiatWallets ? (
                      walletData.fiatWallets.map((item, index) => (
                        <p
                          id="toWalletDrop"
                          key={item.id}
                          style={{
                              borderBottom: walletData.fiatWallets.length - 1 === index ? "none" : "1px #90909039 solid;",
                          }} 
                          onClick={(e) => {
                            handleToWallet(e);
                          }}
                        >
                          {item.Currency.code}
                        </p>
                      ))
                    ) : (
                      <p>Sorry, no wallets here</p>
                    )}
                  </div>
                </div>
              </div>

             {destinationWallet && <div className={style.info_div}>
                    <img src={info_icon} alt="info icon" />
                    <p style={{margin: '0px', color: '#40196D'}}>1 {sourceWallet} = 200 {destinationWallet}</p>
                </div>
                }

              <div 
                style={{marginTop: "20px"}}
                 >
                <label htmlFor="receiveAmt">You Receive</label>
                <div className={style.input}>
                  <input
                    type="number"
                    name="receiveAmt"
                    disabled
                    placeholder="Amount in preferred currency e.g 10 USD"                  
                  />
                </div>
            </div>
            
            <div style={{marginTop: '30px'}}>
                <button
                className={style.save}
              >
                Proceed
              </button>
              </div>

            </form>


            </div>
        </>
     );
}
 
export default ExchangePage;